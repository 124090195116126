<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Review negative keywords
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedApprovalStatus" :items="approvalStati" item-text="name" item-value="name" label="Approval status"
              required :rules="[v => !!(v && v.length) || 'Approval status is required']" prepend-icon="grading">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedProviders" :items="activeProviders" item-text="name" item-value="id" multiple label="Providers" 
              clearable prepend-icon="business">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedVerticals" :items="activeVerticals" item-text="name" item-value="id" multiple label="Verticals" 
              clearable prepend-icon="construction">
            </v-autocomplete>
          </v-col>

          <v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model="keywordSearch" label="Keyword search" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedMatchTypes" :items="matchTypes" multiple label="Match types" clearable prepend-icon="format_size">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-btn class="mt-2" color="primary" @click="search">Search</v-btn>
          </v-col>

          <v-col>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-btn :disabled="!reviewEnabled" class="mt-2" color="success" @click="changeApprovalStatus('Approved')">Approve</v-btn>
            <v-btn :disabled="!reviewEnabled" class="mt-2 ml-5" color="warning" @click="changeApprovalStatus('Declined')">Decline</v-btn>
            <v-btn :disabled="!deleteEnabled" class="mt-2 ml-5" color="error" @click="deleteBookings">Delete</v-btn>
          </v-col>
        </v-row>

      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
      show-select
      v-model="selected"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.approval_status`]="{ item }">
        <v-icon>{{ nkw_getApprovalStatusIcon(item.approval_status) }}</v-icon>
      </template>

      <template v-slot:[`item.notated_keyword`]="{ item }">
        <router-link class="blue--text darken-1" :to="{ name: 'Negative keyword', params: {nkwID: item.keywords_fk }}">
          {{item.notated_keyword}}
        </router-link>
      </template>

      <template v-slot:[`item.comments_psv`]="{ item }">
        <span style="white-space: pre;">{{ formatCommentsPSV(item.comments_psv) }}</span>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import auth from '@/auth'
import dataTablesMixin from '@/mixins/datatables'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NKWReviewTable',

  data () {
    return {
      headers: [
        { text: 'Status', value: 'approval_status' },
        { text: 'Provider', value: 'provider' },
        { text: 'Vertical', value: 'vertical_short_name' },
        { text: 'Keyword', value: 'notated_keyword' },
        { text: 'Territory', value: 'territory' },
        { text: 'Entry by', value: 'entry_by' },
        { text: 'Comments', value: 'comments_psv'},
      ],
      items: [],
      selected: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      selectedProviders: [],
      selectedVerticals: [],
      selectedApprovalStatus: 'Under review',
      keywordSearch: '',
      selectedMatchTypes: [],
      lsOptions: 'nkwReview_options',
      dataEndpoint: '/a/nkw/bookings',
      changeApprovalStatusEndpoint: '/a/nkw/change-approval-status',
      deleteBookingsEndpoint: '/a/nkw/delete-bookings',
    }
  },

  mixins: [dataTablesMixin, nkwMixin],

  computed: {
    activeProviders () {
      return this.$store.state.core.activeProviders
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals
    },
    approvalStati () {
      return this.$store.state.nkw.approvalStati.filter(val => val !== 'Approved')
    },
    deleteEnabled () {
      return this.selected.length > 0
    },
    matchTypes () {
      return this.$store.state.core.matchTypes.filter(val => val !== 'Broad')
    },
    reviewEnabled () {
      return this.selected.length > 0 && (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech'))
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    changeApprovalStatus (newStatus) {
      if (!this.selected) {
        return
      }

      var bookingIDs = []
      this.selected.forEach(function(rec) {
        bookingIDs.push(rec.id)
      })

      var changeObj = {
        'newApprovalStatus': newStatus,
        'bookingIDs': bookingIDs,
      }
      var body = JSON.stringify(changeObj)
      //console.log(body)

      this.$http.put(this.changeApprovalStatusEndpoint, body).then(resp => {
        this.selected = []
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.changeApprovalStatusEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    deleteBookings () {
      if (!this.selected) {
        return
      }

      var bookingIDs = []
      this.selected.forEach(function(rec) {
        bookingIDs.push(rec.id)
      })

      var changeObj = {
        'bookingIDs': bookingIDs,
      }
      var body = JSON.stringify(changeObj)
      //console.log(body)

      this.$http.put(this.deleteBookingsEndpoint, body).then(resp => {
        this.selected = []
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.deleteBookingsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    fetchData: function () {
      this.loading = true
      var myURL = this.dataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], ['keywords_fk', 'region', 'country', 'id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter approval status
      myURL += '&approval_status=' + this.selectedApprovalStatus

      // filter providers
      if (this.selectedProviders.length > 0) {
        myURL += '&providers_fk=' + this.selectedProviders.join('|')
      }

      // filter verticals
      if (this.selectedVerticals.length > 0) {
        myURL += '&verticals_fk=' + this.selectedVerticals.join('|')
      }

      // filter keyword search
      if (this.keywordSearch) {
        myURL += '&keyword=~' + this.keywordSearch + '~'
      }

      // filter match types
      if (this.selectedMatchTypes.length > 0) {
        myURL += '&match_type=' + this.selectedMatchTypes.join('|')
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    formatCommentsPSV (commentsPSV) {
      if (!commentsPSV) {
        return ''
      }
      return commentsPSV.split('| ').join('\n')
    },

    search: function () {
      //let filterObj = {}
      //localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
